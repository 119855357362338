import React from 'react';
import styles from "./segments.module.css";

const App = ({ title, text, img }) => {
  return (
    <div className={styles.SegmentsContainer}>
        <div className={styles.imgContent}>
            <img draggable= 'false' src={img}/>
        </div>
        <div className={styles.SegmentsContent}>
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
    </div>
  );
};

export default App;
import React, { useState } from 'react';
import axios from 'axios';
import styles from './form.module.css';
import { LuLoader2, LuCheckCircle2, LuXCircle } from 'react-icons/lu';

const App = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telephone: '',
    company: '',
    department: '',
    position: '',
    cnpj: '',
    invoicing: '',
    origin: ''
  });

  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus(null);

    try {
      const response = await axios.post('https://client.rapidchargeback.com/api/app/email', formData, {
        headers: {
          'Authorization': 'FHYU8H4PRAPID2023CHARGEBACKFHYU8H4P'
        }
      });

      if (response.status === 200) {
        setStatus('success');
        setFormData({
          name: '',
          email: '',
          telephone: '',
          company: '',
          department: '',
          position: '',
          cnpj: '',
          invoicing: '',
          origin: ''
        });

        // Voltar ao estado original após 2 segundos
        setTimeout(() => {
          setStatus(null);
        }, 1500);
      } else {
        setStatus('error');

        // Voltar ao estado original após 2 segundos
        setTimeout(() => {
          setStatus(null);
        }, 1500);
      }
    } catch (error) {
      setStatus('error');

      // Voltar ao estado original após 2 segundos
      setTimeout(() => {
        setStatus(null);
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.Form}>
      <h3 className={styles.titleblack}>Aumente suas vendas e eficiência com garantia</h3>
      <p className={styles.paragraphblacklight}>
        Explore como nossas soluções podem catalisar a transformação em sua empresa. Escolha a solução ideal para você e entre em contato com um especialista.
      </p>
      <p className={styles.paragraphblack}>
        😊 Não se preocupe, nós nunca enviaremos spam, prometemos!
      </p>
      <div className={styles.formcontent}>
        <form id="formcontato" onSubmit={handleSubmit}>
          <div>
            <input style={{ width: '100%' }} type="text" name="name" value={formData.name} onChange={handleChange} required placeholder="Nome" />
            <input style={{ width: '100%' }} type="email" name="email" value={formData.email} onChange={handleChange} required placeholder="E-mail" />
          </div>
          <div>
            <input style={{ width: '100%' }} type="text" name="telephone" value={formData.telephone} onChange={handleChange} required placeholder="Telefone" />
            <input style={{ width: '100%' }} type="text" name="company" value={formData.company} onChange={handleChange} required placeholder="Nome da empresa" />
          </div>
          <div>
            <input style={{ width: '100%' }} type="text" name="department" value={formData.department} onChange={handleChange} required placeholder="Departamento" />
            <input style={{ width: '100%' }} type="text" name="position" value={formData.position} onChange={handleChange} required placeholder="Cargo" />
          </div>
          <div>
            <input style={{ width: '100%' }} type="text" name="cnpj" value={formData.cnpj} onChange={handleChange} required placeholder="CNPJ" />
            <input style={{ width: '100%' }} type="text" name="invoicing" value={formData.invoicing} onChange={handleChange} required placeholder="Faturamento" />
          </div>
          <input type="text" name="origin" value={formData.origin} onChange={handleChange} required placeholder="Como chegou até a Rapid?" />
          <label className={styles.checkboxlabel}>
            <input
              type="checkbox"
              className={styles.checkboxinput}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className={styles.checkboxcustom}></span>
            <p style={{ textAlign: 'start' }} className={styles.paragraphblacklight}>
              Eu li e aceito a <strong>política de privacidade</strong>
            </p>
          </label>
        </form>
        <button form='formcontato' className={styles.formbutton} style={{ marginTop: '40px' }} type="submit">
          {loading ? (
            <LuLoader2 className={styles.loadingIcon} style={{ display: 'flex' }} size={21}/>
          ) : status === 'success' ? (
            <LuCheckCircle2 className={styles.successIcon} style={{ display: 'flex' }} size={21} />
          ) : status === 'error' ? (
            <LuXCircle className={styles.errorIcon} style={{ display: 'flex' }} size={21} />
          ) : (
            'Enviar'
          )}
        </button>
      </div>
    </div>
  );
};

export default App;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import MainPage from './pages/main/main';
import ChargebackalertPage from './pages/chargebackalert/chargebackalert';

// Definindo títulos de páginas com base nas rotas
const routeTitles = {
  '/': 'Rapid',
  '/product/chargebackalert': 'Alerta de Chargeback - Rapid',
};

// Componente Layout com lógica de título
const RouteTitle = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    const title = routeTitles[currentPath] || 'Rapid';
    document.title = title;
  }, [location.pathname]);

  return <div>{children}</div>;
};

// Componente para redirecionamento de rotas
const RedirectBasedOnPath = () => {
  const location = useLocation();

  if (location.pathname.startsWith('/app')) {
    window.location.href = 'https://app.rapidchargeback.com/app/auth';
    return null;
  }

  return <Navigate to="/" />;
};

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<RouteTitle><RedirectBasedOnPath /></RouteTitle>} />
        <Route path="/" element={<RouteTitle><MainPage /></RouteTitle>} />
        <Route path="/product/chargebackalert" element={<RouteTitle><ChargebackalertPage /></RouteTitle>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;

import React, { useState } from 'react';
import styles from './footer.module.css';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import Logo from '../../images/logo/Logo.svg'
import { useNavigate } from 'react-router-dom';

const App = () => {
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);
    const [isHovered5, setIsHovered5] = useState(false);
    const [isHovered6, setIsHovered6] = useState(false);
    const [isHovered7, setIsHovered7] = useState(false);
    const [isHovered8, setIsHovered8] = useState(false);
    const [isHovered9, setIsHovered9] = useState(false);
    const [isHovered10, setIsHovered10] = useState(false);
    const navigate = useNavigate();

    const handleDocumentacaoClick = () => {
        window.location.href = "https://doc.rapidchargeback.com/";
    };

    const handleSobrenosClick = () => {
      const contatoSection = document.getElementById('Sobrenos');
      if (contatoSection) {
          contatoSection.scrollIntoView({ behavior: 'smooth' });
      } else {
          navigate('/');
          setTimeout(() => {
              const sobrenosSection = document.getElementById('Sobrenos');
              if (sobrenosSection) {
                  sobrenosSection.scrollIntoView({ behavior: 'smooth' });
              }
          }, 300);
      }
    };
  
    const handleContatoClick = () => {
        const contatoSection = document.getElementById('Contato');
        if (contatoSection) {
            contatoSection.scrollIntoView({ behavior: 'smooth' });
        } else {
            navigate('/');
            setTimeout(() => {
                const contatoSection = document.getElementById('Contato');
                if (contatoSection) {
                    contatoSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300);
        }
    };

  return (
    <div className={styles.Footer}>
      <div style={{ height: '2px', backgroundColor: 'rgba(0, 0, 0, 0.3)', width: '100%' }}></div>
      <div className={styles.FooterContainer}>
        <div className={styles.history}>
          <img onClick={() => navigate('/')} draggable= 'false' src={Logo} alt="Logo" />
          <p className={styles.paragraphblacklightmini} style={{ lineHeight: '1.8' }}>
            A Rapid oferece soluções de segurança digital para proteger transações online, minimizando riscos e maximizando conversões. Líder no combate à fraude, proporciona tranquilidade aos seus clientes com tecnologia avançada.
          </p>
        </div>
        <div className={styles.grouplist}>
          <div className={styles.list1}>
            <h3 className={styles.paragraphblack}>REDES SOCIAIS</h3>
            <div style={{ display: 'flex', gap: '20px', marginTop: '15px' }}>
              <FaInstagram className={styles.Instagram} size={29}/>
              <FaLinkedinIn className={styles.Linkedin} size={29}/>
            </div>
          </div>
          <div className={styles.list2}>
            <h3 className={styles.paragraphblack}>INSTITUCIONAL</h3>
            <div>
              <p
                style={{ cursor: 'pointer' }}
                className={styles.paragraphblacklightmini}
                onMouseEnter={() => setIsHovered4(true)}
                onMouseLeave={() => setIsHovered4(false)}
                onClick={handleSobrenosClick}
              >
                Sobre nós
              </p>
              <div className={`${styles.bar} ${isHovered4 ? styles.barAnimation : ""}`} />
            </div>
            <div>
              <p
                style={{ cursor: 'pointer' }}
                className={styles.paragraphblacklightmini}
                onMouseEnter={() => setIsHovered5(true)}
                onMouseLeave={() => setIsHovered5(false)}
                onClick={handleContatoClick}
              >
                Contato
              </p>
              <div className={`${styles.bar} ${isHovered5 ? styles.barAnimation : ""}`} />
            </div>
          </div>
          <div className={styles.list3}>
            <h3 className={styles.paragraphblack}>SOLUÇÕES</h3>
            <div>
              <p
                style={{ cursor: 'pointer' }}
                className={styles.paragraphblacklightmini}
                onMouseEnter={() => setIsHovered6(true)}
                onMouseLeave={() => setIsHovered6(false)}
                onClick={() => navigate('/product/chargebackalert')}
              >
                Alerta de chargeback
              </p>
              <div className={`${styles.bar} ${isHovered6 ? styles.barAnimation : ""}`} />
            </div>
            <p style={{ opacity: '0.5', cursor: 'default' }} className={styles.paragraphblacklightmini}>Anti Fraude Transacional</p>
            <p style={{ opacity: '0.5', cursor: 'default' }} className={styles.paragraphblacklightmini}>Card Check Secure</p>
            <p style={{ opacity: '0.5', cursor: 'default' }} className={styles.paragraphblacklightmini}>Onboarding & KYC</p>
          </div>
          <div className={styles.list4}>
            <h3 className={styles.paragraphblack}>DESENVOLVEDORES</h3>
            <div>
              <p
                style={{ cursor: 'pointer' }}
                className={styles.paragraphblacklightmini}
                onMouseEnter={() => setIsHovered10(true)}
                onMouseLeave={() => setIsHovered10(false)}
                onClick={handleDocumentacaoClick}
              >
                Documentação API
              </p>
              <div className={`${styles.bar} ${isHovered10 ? styles.barAnimation : ""}`} />
            </div>
            <p style={{ opacity: '0.5', cursor: 'default' }} className={styles.paragraphblacklightmini}>Central de Ajuda</p>
          </div>
        </div>
      </div>
      <div className={styles.list5}>
        <h3 className={styles.paragraphblack}>CONTATO</h3>
        <a style={{ textDecoration: 'none' }} href="mailto:contato@rapidchargeback.com">
          <p className={styles.paragraphblacklightmini}>contato@rapidchargeback.com</p>
        </a>
      </div>
      <div className={styles.FooterBottom}>
        <div style={{ height: '2px', backgroundColor: 'rgba(0, 0, 0, 0.3)', width: '100%' }}></div>
        <p className={styles.paragraphblacklightmini} style={{ textAlign: 'center', maxWidth: '900px' }}>
          Termos e condições de uso • Políticas de privacidade • Uso de cookies • Rapid 2024 © Todos os direitos reservados. Nosso endereço: Av Ayrton Senna 2500, Office 3 sala 227 - Barra da Tijuca - RJ
        </p>
      </div>
    </div>
  );
};

export default App;
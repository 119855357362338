import React from 'react';
import styles from "./cardimage.module.css";

const App = ({ text, image}) => {

  return (
    <div className={styles.cardContainer}>
        <img draggable= 'false' src={image}/>
        <h2>{text}</h2>
    </div>
  );
};

export default App;
import React from 'react';
import styles from "./cardbutton.module.css";
import { HiArrowUpRight } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';

const App = ({ title, text, buttonLink, isDisabled }) => {
  const navigate = useNavigate();

  const buttonClass = isDisabled ? `${styles.disabledButton}` : styles.button;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContent}>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      <div className={styles.buttonContent}>
        <button
          className={buttonClass}
          onClick={() => !isDisabled && navigate(buttonLink)}
          disabled={isDisabled}
        >
          <HiArrowUpRight size={22}/>
        </button>
      </div>
    </div>
  );
};

export default App;
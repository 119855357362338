import React, {useEffect} from 'react';
import styles from "./chargebackalert.module.css"
import { useNavigate } from 'react-router-dom';

import Percent from '../../images/icon/percent.svg'
import Blocks from '../../images/icon/blocks.svg'
import Paper from '../../images/icon/paper.svg'
import Flag from '../../images/icon/flag.svg'

import Shop from '../../images/icon/shop.svg'
import Finance from '../../images/icon/finance.svg'
import Chip from '../../images/icon/chip.svg'
import Service from '../../images/icon/service.svg'
import Company from '../../images/icon/company.svg'

import MainImage from '../../images/chargebackalert/MainImage.png'
import MiddleFluxoImage from '../../images/chargebackalert/MiddleFluxoImage.png'
import MiddleFluxoImageMoblie from '../../images/chargebackalert//MiddleFluxoImageMobile.png'
import MiddleSegmentosImage from '../../images/chargebackalert//MiddleSegmentosImage.png'
import MiddlePrecoImage from '../../images/chargebackalert/MiddlePrecoImage.png'
import Mouser from "../../images/icon/mouser.svg"
import Star from '../../images/icon/star.svg'

import Header from '../../components/header/header'
import Esteira from '../../components/belt/belt'
import Cards from "../../components/cardimage/cardimage"
import Segments from "../../components/segments/segments"
import Footer from '../../components/footer/footer'
import FluxoLogos from '../../components/flow/flow'

const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleContatoClick = () => {
        const contatoSection = document.getElementById('Contato');
        if (contatoSection) {
            contatoSection.scrollIntoView({ behavior: 'smooth' });
        } else {
            navigate('/');
            setTimeout(() => {
                const contatoSection = document.getElementById('Contato');
                if (contatoSection) {
                    contatoSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300);
        }
    };

return (
    <div className={styles.container}>
        <div className={styles.Header}>
            <Header/>
        </div>
        <div className={styles.Main}>
            <div className={styles.MainContainer}>
                <div className={styles.MainContent}>
                    <div className={styles.MainTexts}>
                        <h1>Evite 90% dos chargebacks e proteja-se contra multas e bloqueios das bandeiras e adquirentes.</h1>
                        <h2>Reduza o índice de chargeback e mantenha sua operação segura e eficiente.</h2>
                        <div>
                            <p className={styles.paragraphblacklightmini}>Comece em 24 horas</p>
                            <p className={styles.paragraphblacklightmini}>Reduza sua taxa de chargeback </p>
                            <p className={styles.paragraphblacklightmini}>Totalmente prático com reembolsos automatizados</p>
                            <button style={{ marginTop: '35px' }} className={styles.mainbutton} onClick={handleContatoClick}>Fale com especialistas</button>
                        </div>
                    </div>
                </div>
                <div className={styles.MainMobileImage}>
                    <img draggable= 'false' src={MainImage}/>
                </div>
            </div>
            <div className={styles.MainMouserImage}>
                <img draggable= 'false' src={Mouser}/>
            </div>
        </div>
        <div className={styles.MiddleEmpresas}>
            <Esteira/>
        </div>
        <div className={styles.MiddleFluxo}>
            <h3 className={styles.titleblack}>Veja como funciona o fluxo</h3>
            <img className={styles.MiddleFluxoDesktop} draggable= 'false' src={MiddleFluxoImage}/>
            <div className={styles.MiddleFluxoLogos}>
                <img draggable= 'false' src={MiddleFluxoImageMoblie}/>
                <FluxoLogos/>
            </div>
        </div>
        <div className={styles.MiddleSolucoes}>
            <h3 className={styles.titleblack}>Aumente os lucros enquanto previne chargebacks</h3>
            <div className={styles.SolucoesCards}>
                <Cards
                    image={Percent}
                    text="Reduza significativamente a taxa de chargebacks"
                />
                <Cards
                    image={Blocks}
                    text="Evite programas de monitoramento das bandeiras de cartões"
                />
                <Cards
                    image={Paper}
                    text="Aceite mais transações"
                />
                <Cards
                    image={Flag}
                    text="Compatível com as bandeiras visa e mastercard"
                />
            </div>
        </div>
        <div className={styles.MiddlePreco}>
            <div className={styles.MiddlePrecoTop}>
                <div className={styles.MiddlePrecoTopContainer}>
                    <div>
                        <h3 style={{ fontWeight: '500', maxWidth: '500px' }} className={styles.titleblack}>Mais de <strong>1.000.000,00</strong> de <strong>chargeback evitados</strong></h3>
                        <p className={styles.paragraphblacklight}>Temos o orgulho de anunciar que nosso produto Alerta de Chargeback já preveniu com sucesso mais de 1 milhão de chargebacks, proporcionando tranquilidade e segurança aos nossos clientes.</p>
                    </div>
                </div>
            </div>
            <div className={styles.MiddlePrecoImage}>
                <div>
                    <img draggable= 'false' src={MiddlePrecoImage}/>
                </div>
            </div>
            <div className={styles.MiddlePrecoBottom}>
                <div className={styles.MiddlePrecoBottomContainer}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '35px' }}>
                        <div className={styles.Star}>
                            <img draggable= 'false' src={Star}/>
                        </div>
                        <h3 style={{ maxWidth: '400px' }} className={styles.titlewhite}>Pague apenas por chargeback evitados</h3>
                        <div className={styles.MiddlePrecoContainerPreco}>
                            <div className={styles.MiddlePrecoContentPreco}>
                                <div>
                                    <p className={styles.paragraphwhitelight}>Alertas a partir de</p>
                                    <h3 style={{ marginTop: '5px' }} className={styles.titlewhite}>$ 5 dólares</h3>
                                </div>
                                <p style={{ fontSize: '18px', marginBottom: '4px' }} className={styles.titlewhite}>Por alerta</p>
                            </div>
                            <div>
                                <button className={styles.mainbutton} onClick={handleContatoClick}>Fale com especialistas</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.MiddleSegmentosBackContainer}>
            <div className={styles.MiddleSegmentos}>
                <div className={styles.MiddleSegmentosContainer}>
                    <div className={styles.MiddleSegmentosMiddle}>
                        <Segments
                            img={Shop}
                            title="Reembolsos automatizados"
                            text="Nosso sistema processa reembolsos de forma autônoma, garantindo transações oportunas e descomplicadas."
                        />
                        <Segments
                            img={Finance}
                            title="Integração rápida"
                            text="Experimente uma configuração descomplicada com nossa promessa de integração em menos de 24 horas."
                        />
                        <Segments
                            img={Chip}
                            title="Duplicação Zero"
                            text="Diga adeus aos alertas redundantes. Nosso sistema é simplificado para evitar duplicatas."
                        />
                        <Segments
                            img={Service}
                            title="Alertas verificados"
                            text="Cada alerta passa por um rigoroso processo de confirmação, garantindo que você receba apenas notificações genuínas."
                        />
                        <Segments
                            img={Company}
                            title="Redução de estorno"
                            text="Garantimos uma redução significativa nos estornos, garantindo ainda mais segurança às suas transações."
                        />
                        <Segments
                            img={Company}
                            title="Evite taxa de estorno"
                            text="Reembolsar transações de forma proativa antes que elas se transformem em estornos significa que você não precisa pagar nenhuma taxa de estorno."
                        />
                    </div>
                    <div className={styles.MiddleSegmentosBottom}>
                        <button className={styles.mainbutton} onClick={handleContatoClick}>Falar com especialistas</button>
                    </div>
                </div>
                <div className={styles.MiddleSegmentosImage}>
                    <img draggable= 'false' src={MiddleSegmentosImage}/>
                </div>
            </div>
        </div>
        <div className={styles.Footer}>
            <Footer/>
        </div>
    </div>
  );
};

export default App;